.main__container {
  width: 80%;
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 200px;
}
.skills__image {
  height: 55px;
  margin: 20px;
}
.skills__image:hover {
  transform: scale(1.2);
}
