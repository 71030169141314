.email__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  border: 2px solid white;
  margin-bottom: 20px;
  margin-top: 50px;
  width: 50%;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer > a {
  padding: 0 20px;
}
.footer > a:hover {
  color: blue;
  transition: 500ms;
  transform: scale(1.3);
}
footer > p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-weight: bold;
}
.form__box {
  margin: 25px 70px 75px 70px;
}
@media (max-width: 800px) {
  .form__box {
    margin: 40px 10px;
  }
  .email__container {
    width: 85%;
    margin-bottom: 75px;
  }
}
