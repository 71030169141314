* {
  color: white;
  /* background-color: black; */
}
.navbar {
  display: flex;
  justify-content: space-between;
  backdrop-filter: blur(10px);
  /* background-color: rgba(255, 255, 255, 0.3); */
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1;
  /* background-color: rgb(4, 4, 41); */

  border-bottom: 2px solid rgb(19, 73, 124);
}
.navbar.active {
  /* background-color: rgb(15, 12, 12); */
  background-color: rgba(0, 0, 0, 0.384);
  backdrop-filter: blur(10px);
}

.page-nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: white 2px solid; */
  width: 100vw;
}
.middle__icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid white; */
  flex: 0.5;
}
.headshot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex: 0.25; */
}
.headshot > img {
  width: 4rem;
  height: 4rem;
  padding-left: 10px;
}
.social__media {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0.2;
  padding-right: 10px;

  /* border: 1px solid white; */
}
.home {
  position: relative;
  /* border: 1px solid white; */
  cursor: pointer;
}
.home > p {
  position: absolute;
  opacity: 0;
  top: 50%;
  margin: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 400ms;
  font-family: "Orbitron", sans-serif;
}
.home:hover > p {
  opacity: 1;
}
.home:hover > div {
  opacity: 0;
}
.resume__icon {
  padding-right: 30px;
  font-weight: bold;
}
.download__icon {
  margin-left: -25px;
}

@media (max-width: 650px) {
  .headshot > img {
    display: none;
  }
  .middle__icons {
    flex: 1;
    margin-left: 5px;
  }
  .navbar {
    height: 80px;
    background-color: black;
  }
}
@media (max-width: 750px) {
  .navbar {
    background-color: black;
  }
}
