.fists-sentence {
  font-size: xx-large;
  font-family: "Orbitron", sans-serif;
  font-weight: bold;
  color: rgb(196, 246, 248);
}
.second-sentence {
  font-size: larger;
  font-family: "Orbitron", sans-serif;
  font-weight: bold;
  color: rgb(178, 244, 247);
}
.third-sentence {
  font-size: larger;
  font-family: "Orbitron", sans-serif;
  font-weight: bold;
  color: rgb(178, 244, 247);
}
.Last_sentence {
  font-size: larger;
  font-family: "Orbitron", sans-serif;
  font-weight: bold;
  color: rgb(178, 244, 247);
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 20px;
}
.headshot_about {
  border-radius: 9.5rem;
  width: 300px;
  margin-bottom: 20px;
}
.image-div {
  transition: 500ms;
}
.about__text {
  width: 70%;
  text-align: left;
  margin-right: 30px;
  margin-left: 30px;
}

@media (max-width: 500px) {
  .headshot_about {
    border-radius: 4.5rem;
    width: 150px;
    margin-bottom: 50px;
  }
  .fists-sentence {
    font-size: x-large;
  }
  .about__text {
    margin-left: 5px;
    margin-right: 5px;
    width: 95%;
    margin-bottom: 20px;
  }
  .about__text > p {
    font-size: small;
  }
  .image-div {
    margin-top: 85px;
  }
  .fists-sentence {
    font-size: x-large;
  }
  .second-sentence {
    font-size: 15px;
  }
  .third-sentence {
    font-size: 15px;
  }
  .Last_sentence {
    font-size: 15px;
  }
}
